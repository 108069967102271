import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'
import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import TokenGrid from "../components/tokenGrid";

class Product extends React.Component {
  render() {
    const posts = get(this, 'props.data.allContentfulToken.nodes')
    return (
      <Layout>
      <SEO
        keywords={[`tokens`, `cryptocurrency`, `crypto`, `exchange`]}
        title="Tokensack.com - The hottest cryptocurrency exchange tokens"
      />
        <div>
        <Hero />

      <TokenGrid tokens={posts} id='tokens'/>

      </div>
      </Layout>
    )
  }
}

export default Product

export const query = graphql`
{
  allContentfulToken {
    nodes {
      tokenName
      symbol
      slug
      coingeckoId
      icon {
        fixed {
          width
          height
          src
          srcSet
        }
      }
    }
  }
}
`
