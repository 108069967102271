import React from "react";
import HeroImg from '../images/heroImg.png'

function Hero () {
  return(
    <main className="relative mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
      <div className="lg:grid lg:grid-cols-12 lg:gap-8">
        <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
          <h2 className="mt-1 text-4xl tracking-tight leading-10 font-bold text-gray-900 sm:leading-none sm:text-5xl lg:text-4xl xl:text-5xl">
            The hottest cryptocurrency
            <br className="hidden md:inline"></br>
            <span className="text-indigo-600"> exchange tokens</span>
          </h2>
          <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
            Explore the top cryptocurrencies issued by exchanges. From discounted trading fees to exclusive airdrop access, exchange tokens are increasing in popularity and market cap.
          </p>
          <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
            {/*<p className="text-base font-medium text-gray-900">
              Sign up to get notified when it’s ready.
            </p>*/}

            <p className="mt-3 text-sm leading-5 text-gray-500">
              Tokensacks does not provide investment advice. Read our disclaimer
              <a href="/disclaimer" className="font-medium text-gray-600"> here</a>.
            </p>
          </div>
        </div>
        <div className="mt-5 relative sm:max-w-lg sm:mx-auto lg:-mt-14 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
          <div className="relative mx-auto w-full rounded-lg">
              <img className="w-full" src={HeroImg} alt="TokenSack tokens"></img>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Hero;
