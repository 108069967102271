import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import PropTypes from 'prop-types';
import axios from 'axios';
import countapi from 'countapi-js';

class TokenGrid extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      views: []
    }
  }

  componentDidMount() {
    let requestPromises = []
    for (let token in this.props.tokens) {
      let id = this.props.tokens[token].coingeckoId
      let url = 'https://api.coingecko.com/api/v3/coins/'+id+'?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false'
      requestPromises.push(axios.get(url).then(result => new Promise(resolve => resolve(result.data))))

    }

    Promise.all(requestPromises).then(res => {
      this.setState({data: res})
    })


    let viewPromises = []
    for (let token in this.props.tokens) {
      let slug = this.props.tokens[token].slug
      viewPromises.push(countapi.get('tokensack.com', slug).then((result) => new Promise(resolve => resolve({key: slug, value: result.value}))))
    }

    Promise.all(viewPromises).then(res => {
      this.setState({views: res})
    })



}

  render() {

    {/*function showPrice(coingeckoId) {
      this.state.data.map(entry => {
        if (entry.id == token.coingeckoId){
          return(<span className="ml-3">{token.slug}</span>)
        }
        })
    }

*/}

  console.log(this.state)

    return(

      <div className="relative mt-8 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-20 xl:mt-24">
      <ul className="relative grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">

      {this.props.tokens.map(token => {
        return (

          <Link to={"/"+token.slug} key={token.slug}>


          <li className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow">
            <div className="flex-1 flex flex-col p-8">
              <img className="w-16 h-16 flex-shrink-0 mx-auto rounded-full" src={token.icon.fixed.src} alt=""></img>
              <h3 className="mt-6 text-gray-900 text-sm leading-5 font-medium">{token.tokenName}</h3>
              <dl className="mt-1 flex-grow flex flex-col justify-between">
                <dt className="sr-only">Symbol</dt>
                <dd className="text-gray-400 text-xs leading-5">{token.symbol}</dd>
              </dl>
            </div>
            <div className="">
              <div className="-mt-px flex">
                <div className="w-0 flex-1 flex">
                  <a href="#" className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">


                  <svg  className="w-5 h-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/></svg>



                    {this.state.data.filter(entry => entry.id == token.coingeckoId).map(entry => <span className='text-gray-600'>{entry.market_data.current_price.usd.toFixed(2)}</span>)
                  }

                  </a>
                </div>
                <div className="-ml-px w-0 flex-1 flex">
                  <a href="#" className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm leading-5 text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 transition ease-in-out duration-150">

                    {this.state.views.filter(entry => entry.key == token.slug).map(entry => <span className='text-gray-600'>{entry.value}</span>)
                  }
                  <svg className="w-5 h-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/></svg>


                  </a>
                </div>
              </div>
            </div>
          </li>
          </Link>

        )})}

      </ul>
    </div>
    )
  }
}

export default TokenGrid;

TokenGrid.propTypes = {
  tokens: PropTypes.array
};
